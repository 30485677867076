import React, { useRef } from 'react';
import classnames from 'classnames';
import { prettyMoneyHybrid } from '../../../../helpers/utils';
import { SpecTriangleIcon } from '../../../../components/UI/Icons';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import s from './index.module.scss';

export const IncomeBlock = ({ earnings, className = 'income-block' }) => {
  const ref = useRef();
  const [show, setShow] = React.useState(false);
  useOutsideClick(ref, () => setShow(false), show);

  return (
    <div className={classnames('income_block', s.income_block, className)}>
      <p className="f-16">Annual rate</p>
      <p className={classnames(s.income_block__rate, 'f-36 f-500')}>
        {earnings?.rate ? `${earnings.rate}%` : '—'}
      </p>
      <p className={classnames(s.income_block__label, 'f-16')}>
        All-time invested
      </p>
      <p className="f-500 f-26">
        {prettyMoneyHybrid(earnings && earnings.invested)}
      </p>
      <p className={classnames(s.income_block__label, 'f-16')}>
        All-time earned
      </p>
      <div
        ref={ref}
        className={s.income_block__cash}
        onClick={() => setShow(!show)}
      >
        <div className={s.income_block__cash_inner}>
          <p
            className={classnames(s.income_block__white, 'f-500 f-26 tl')}
            style={{ cursor: 'pointer' }}
          >
            {prettyMoneyHybrid(
              earnings && earnings.earned + earnings.earned_bonuses
            )}
          </p>
          {show && earnings?.earned_bonuses > 0 && (
            <div
              className={s.income_block__popup}
              onClick={e => e.stopPropagation()}
            >
              <SpecTriangleIcon className={s.income_block__popup_triangle} />
              <div className={s.income_block__popup_list}>
                <div className={s.income_block__popup_row}>
                  <div className="f-16 f-500">Investment earnings</div>
                  <div className="f-26 f-500 tl">
                    {prettyMoneyHybrid(earnings.earned)}
                  </div>
                </div>
                <div className={s.income_block__popup_row}>
                  <div className="f-16 f-500">Accrued</div>
                  <div className="f-26 f-500 tl">
                    {prettyMoneyHybrid(earnings.accrued)}
                  </div>
                </div>
                <div className={s.income_block__popup_row}>
                  <div className="f-16 f-500">Bonus earnings</div>
                  <div className="f-26 f-500 tl">
                    {prettyMoneyHybrid(earnings.earned_bonuses)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
