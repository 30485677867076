import { METADATA } from '../../helpers/metadata';
import {
  GET_AGREEMENTS_BY_FUNDRAISING_ID,
  GET_LOANS_BY_FUNDRAISING_ID,
  GET_LOAN_TERMS,
  GET_LOANS_LIST,
  GET_PAYMENT_HISTORY,
  GET_REPAYMENT_SCHEDULE,
  GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID,
  GET_REPAYMENT_SCHEDULE_BY_LOAN_ID,
  GET_STARTUPS_BY_FUNDRAISING_ID,
  GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID,
  GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID,
  GET_SELLING_LOANS,
  GET_LOAN_SECURITY_BY_FUNDRAISING_ID,
  GET_LOAN_EARNINGS_BY_FUNDRAISING_ID,
} from '../types/loanTypes';

const initialState = {
  loansList: { ...METADATA.default, data: null },
  repaymentSchedule: { ...METADATA.default, data: null },
  repaymentScheduleByLoanID: { ...METADATA.default, data: null },
  loanTerms: { ...METADATA.default, data: null },
  paymentHistory: { ...METADATA.default, data: null },
  loansByFundraisingID: { ...METADATA.default, data: null },
  loanEarningsByFundraisingID: { ...METADATA.default, data: null },
  fundsInRecoveryByFundraisingID: { ...METADATA.default, data: null },
  startupsByFundraisingID: { ...METADATA.default, data: null },
  repaymentScheduleByFundraisingID: { ...METADATA.default, data: null },
  loanSecurityByFundraisingID: { ...METADATA.default, data: null },
  agreementsByFundraisingID: { ...METADATA.default, data: null },
  loanPaymentHistoryByFundraisingID: { ...METADATA.default, data: null },
  sellingLoans: { ...METADATA.default, data: null },
};

const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOANS_LIST.REQUEST:
      return {
        ...state,
        loansList: { ...state.loansList, ...METADATA.request },
      };
    case GET_LOANS_LIST.SUCCESS:
      return {
        ...state,
        loansList: { ...METADATA.success, data: action.payload },
      };
    case GET_LOANS_LIST.FAILURE:
      return {
        ...state,
        loansList: {
          ...state.loansList,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_LOAN_TERMS.REQUEST:
      return {
        ...state,
        loanTerms: { ...state.loanTerms, ...METADATA.request },
      };
    case GET_LOAN_TERMS.SUCCESS:
      return {
        ...state,
        loanTerms: { ...METADATA.success, data: action.payload },
      };
    case GET_LOAN_TERMS.FAILURE:
      return {
        ...state,
        loanTerms: {
          ...state.loanTerms,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_PAYMENT_HISTORY.REQUEST:
      return {
        ...state,
        paymentHistory: { ...state.paymentHistory, ...METADATA.request },
      };
    case GET_PAYMENT_HISTORY.SUCCESS:
      return {
        ...state,
        paymentHistory: { ...METADATA.success, data: action.payload },
      };
    case GET_PAYMENT_HISTORY.FAILURE:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_REPAYMENT_SCHEDULE.REQUEST:
      return {
        ...state,
        repaymentSchedule: { ...state.repaymentSchedule, ...METADATA.request },
      };
    case GET_REPAYMENT_SCHEDULE.SUCCESS:
      return {
        ...state,
        repaymentSchedule: { ...METADATA.success, data: action.payload },
      };
    case GET_REPAYMENT_SCHEDULE.FAILURE:
      return {
        ...state,
        repaymentSchedule: {
          ...state.repaymentSchedule,
          ...METADATA.error,
          error: action.error,
        },
      };
    case GET_REPAYMENT_SCHEDULE_BY_LOAN_ID.REQUEST:
      return {
        ...state,
        repaymentScheduleByLoanID: {
          ...state.repaymentScheduleByLoanID,
          ...METADATA.request,
        },
      };
    case GET_REPAYMENT_SCHEDULE_BY_LOAN_ID.SUCCESS:
      return {
        ...state,
        repaymentScheduleByLoanID: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_REPAYMENT_SCHEDULE_BY_LOAN_ID.FAILURE:
      return {
        ...state,
        repaymentScheduleByLoanID: {
          ...state.repaymentScheduleByLoanID,
          ...METADATA.error,
          error: action.error,
        },
      };

    //

    case GET_LOANS_BY_FUNDRAISING_ID.REQUEST:
      return {
        ...state,
        loansByFundraisingID: {
          ...state.loansByFundraisingID,
          ...METADATA.request,
          data: null,
        },
      };
    case GET_LOANS_BY_FUNDRAISING_ID.SUCCESS:
      return {
        ...state,
        loansByFundraisingID: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_LOANS_BY_FUNDRAISING_ID.FAILURE:
      return {
        ...state,
        loansByFundraisingID: {
          ...state.loansByFundraisingID,
          ...METADATA.error,
          error: action.error,
        },
      };

    case GET_LOAN_EARNINGS_BY_FUNDRAISING_ID.REQUEST:
      return {
        ...state,
        loanEarningsByFundraisingID: {
          ...state.loanEarningsByFundraisingID,
          ...METADATA.request,
          data: null,
        },
      };
    case GET_LOAN_EARNINGS_BY_FUNDRAISING_ID.SUCCESS:
      return {
        ...state,
        loanEarningsByFundraisingID: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_LOAN_EARNINGS_BY_FUNDRAISING_ID.FAILURE:
      return {
        ...state,
        loanEarningsByFundraisingID: {
          ...state.loanEarningsByFundraisingID,
          ...METADATA.error,
          error: action.error,
        },
      };

    case GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID.REQUEST:
      return {
        ...state,
        fundsInRecoveryByFundraisingID: {
          ...state.fundsInRecoveryByFundraisingID,
          ...METADATA.request,
          data: null,
        },
      };
    case GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID.SUCCESS:
      return {
        ...state,
        fundsInRecoveryByFundraisingID: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID.FAILURE:
      return {
        ...state,
        fundsInRecoveryByFundraisingID: {
          ...state.fundsInRecoveryByFundraisingID,
          ...METADATA.error,
          error: action.error,
        },
      };

    case GET_STARTUPS_BY_FUNDRAISING_ID.REQUEST:
      return {
        ...state,
        startupsByFundraisingID: {
          ...state.startupsByFundraisingID,
          ...METADATA.request,
          data: null,
        },
      };
    case GET_STARTUPS_BY_FUNDRAISING_ID.SUCCESS:
      return {
        ...state,
        startupsByFundraisingID: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_STARTUPS_BY_FUNDRAISING_ID.FAILURE:
      return {
        ...state,
        startupsByFundraisingID: {
          ...state.startupsByFundraisingID,
          ...METADATA.error,
          error: action.error,
        },
      };

    case GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID.REQUEST:
      return {
        ...state,
        repaymentScheduleByFundraisingID: {
          ...state.repaymentScheduleByFundraisingID,
          ...METADATA.request,
          data: null,
        },
      };
    case GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID.SUCCESS:
      return {
        ...state,
        repaymentScheduleByFundraisingID: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID.FAILURE:
      return {
        ...state,
        repaymentScheduleByFundraisingID: {
          ...state.repaymentScheduleByFundraisingID,
          ...METADATA.error,
          error: action.error,
        },
      };

    case GET_LOAN_SECURITY_BY_FUNDRAISING_ID.REQUEST:
      return {
        ...state,
        loanSecurityByFundraisingID: {
          ...METADATA.request,
          data: null,
        },
      };
    case GET_LOAN_SECURITY_BY_FUNDRAISING_ID.SUCCESS:
      return {
        ...state,
        loanSecurityByFundraisingID: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_LOAN_SECURITY_BY_FUNDRAISING_ID.FAILURE:
      return {
        ...state,
        loanSecurityByFundraisingID: {
          ...state.loanSecurityByFundraisingID,
          ...METADATA.error,
          error: action.error,
        },
      };

    case GET_AGREEMENTS_BY_FUNDRAISING_ID.REQUEST:
      return {
        ...state,
        agreementsByFundraisingID: {
          ...state.agreementsByFundraisingID,
          ...METADATA.request,
          data: null,
        },
      };
    case GET_AGREEMENTS_BY_FUNDRAISING_ID.SUCCESS:
      return {
        ...state,
        agreementsByFundraisingID: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_AGREEMENTS_BY_FUNDRAISING_ID.FAILURE:
      return {
        ...state,
        agreementsByFundraisingID: {
          ...state.agreementsByFundraisingID,
          ...METADATA.error,
          error: action.error,
        },
      };

    case GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID.REQUEST:
      return {
        ...state,
        loanPaymentHistoryByFundraisingID: {
          ...state.loanPaymentHistoryByFundraisingID,
          ...METADATA.request,
          data: null,
        },
      };
    case GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID.SUCCESS:
      return {
        ...state,
        loanPaymentHistoryByFundraisingID: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID.FAILURE:
      return {
        ...state,
        loanPaymentHistoryByFundraisingID: {
          ...state.loanPaymentHistoryByFundraisingID,
          ...METADATA.error,
          error: action.error,
        },
      };

    case GET_SELLING_LOANS.REQUEST:
      return {
        ...state,
        sellingLoans: {
          ...state.sellingLoans,
          ...METADATA.request,
        },
      };
    case GET_SELLING_LOANS.SUCCESS:
      return {
        ...state,
        sellingLoans: {
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_SELLING_LOANS.FAILURE:
      return {
        ...state,
        sellingLoans: {
          ...state.sellingLoans,
          ...METADATA.error,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default loanReducer;
