import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DropdownBase } from '../../../components/Dropdown/base';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import { prettyFloatMoney } from '../../../helpers/utils';
import { getIncreasedIncomeStatus } from '../../../store/actions/investActions';
import s from './index.module.scss';

export const IncreasedIncomeBanner = ({ className }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { increasedIncomeStatus } = useSelector(state => state.investStore);
  const { balance } = useSelector(state => state.dashboardStore);
  const { data: payload } = increasedIncomeStatus;

  useEffect(() => {
    dispatch(getIncreasedIncomeStatus());
  }, []);

  if (!payload?.status || location.pathname === APP_LINKS.affiliate)
    return null;

  return (
    <div className={classnames(s.wrapper, className)}>
      {balance?.invested >= 10000 ? (
        <AlreadyHasIncreasedIncome />
      ) : (
        <>
          {payload.status === 'get_increased_income' && <IncreasedIncome />}
          {payload.status === 'keep_benefiting_save' && (
            <BenefitingSave data={payload} />
          )}
          {payload.status === 'keep_benefiting_done' && (
            <BenefitingDone data={payload} />
          )}
        </>
      )}
    </div>
  );
};

const IncreasedIncome = () => (
  <DropdownBase
    render={({ open, setOpen }) => (
      <div className={s.income}>
        <div className="f-16-24 f-400">
          <span className="f-500">Increase your income by 1% per month</span> on
          the remaining Group A loans!{' '}
          {!open && (
            <button
              type="button"
              onClick={() => setOpen(!open)}
              className="underline"
            >
              Learn more
            </button>
          )}
        </div>
        <div
          className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
          onClick={() => setOpen(false)}
        >
          <div style={{ paddingTop: '15px' }}>
            The Increased income of 1% is accrued monthly on the outstanding
            loan A principal and paid in the last month of the loan term.
            <br />
            Simply maintain regular monthly deposits and investments of at least
            €100 per month during the full loan repayment period.
            <br />
            Otherwise, you will receive a Basic income of 0.75% per month on the
            outstanding principal amount of loan A.
            <br />
            The offer is valid for the investment rounds starting from April
            2024.
          </div>
        </div>
      </div>
    )}
  />
);

const BenefitingSave = ({ data }) => (
  <DropdownBase
    render={({ open, setOpen }) => (
      <div className={s.benefit_save}>
        <div className="f-16-24 f-400">
          <span className="f-500">
            Save your{' '}
            <button
              type="button"
              onClick={() => setOpen(!open)}
              className="underline"
            >
              Increased income
            </button>{' '}
            of 1% per month
          </span>{' '}
          on your remaining Group A loans!
          {!!(data.required_investments || data.required_monthly_top_ups) && (
            <ul className={s.benefit_save__list}>
              {!!data.required_monthly_top_ups && (
                <li>
                  Top up your account with at least{' '}
                  {prettyFloatMoney(data.required_monthly_top_ups)} by the end
                  of this round.{' '}
                  <MyLink href={APP_LINKS.addFunds} underline>
                    Add funds
                  </MyLink>
                </li>
              )}
              {!!data.required_investments && (
                <li>
                  Invest an additional{' '}
                  {prettyFloatMoney(data.required_investments)} in the current
                  round.{' '}
                  <MyLink href={APP_LINKS.investing} underline>
                    Allocate funds
                  </MyLink>
                </li>
              )}
            </ul>
          )}
        </div>
        <div
          className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
          onClick={() => setOpen(false)}
        >
          <div style={{ paddingTop: '15px' }}>
            The Increased income of 1% is accrued monthly on the outstanding
            loan A principal and paid in the last month of the loan term.
            <br />
            Simply maintain regular monthly deposits and investments of at least
            €100 per month during the full loan repayment period.
            <br />
            Otherwise, you will receive a Basic income of 0.75% per month on the
            outstanding principal amount of loan A.
            <br />
            The offer is valid for the investment rounds starting from April
            2024.
          </div>
        </div>
      </div>
    )}
  />
);

const BenefitingDone = () => (
  <DropdownBase
    render={({ open, setOpen }) => (
      <div className={s.income}>
        <div className="f-16-24 f-400">
          All set! Now you can{' '}
          <span className="f-500">
            keep benefiting from the Increased income of 1% per month
          </span>{' '}
          on your remaining Group A loans!{' '}
          {!open && (
            <button
              type="button"
              onClick={() => setOpen(!open)}
              className="underline"
            >
              Learn more
            </button>
          )}
        </div>
        <div
          className={classnames(s.drop, open && s.drop_open, 'f-16-24 f-400')}
          onClick={() => setOpen(false)}
        >
          <div style={{ paddingTop: '15px' }}>
            You’ve successfully met all the requirements to receive an Increased
            income in this round!
            <br />
            The Increased income of 1% is accrued monthly on the outstanding
            loan A principal and paid in the last month of the loan term.
            <br />
            Simply maintain regular monthly deposits and investments of at least
            €100 per month during the full loan repayment period.
            <br />
            Otherwise, you will receive a Basic income of 0.75% per month on the
            outstanding principal amount of loan A.
            <br />
            The offer is valid for the investment rounds starting from April
            2024.
          </div>
        </div>
      </div>
    )}
  />
);

const AlreadyHasIncreasedIncome = () => {
  return (
    <div className="f-16-24 f-400">
      Congratulations, your active investment balance is above 10,000 euros. You
      receive your Increased income of 1% per month on your remaining Group A
      loans! Keep your active investment balance at least €10,000 and enjoy
      increased returns.
    </div>
  );
};
