export default class Pathes {
  static Auth = class {
    static login = '/auth/investor/2fa_login/';
    static loginCodeStatus = '/auth/login_code_status/';
    static submitLoginCode = '/auth/submit_login_code/';
    static resendLoginCode = '/auth/resend_login_code/';
    static resendWhatsAppLoginCode = '/auth/resend_whatsapp_login_code/';
    static forgotPassword = '/auth/forgotPassword/';
    static resetPassword = '/auth/resetPassword/';
    static verifyToken = '/auth/verifyToken/';
    static changePassword = '/auth/changePassword/';
  };

  static Logger = class {
    static message = '/telegram_send_error/';
  };

  static Company = class {
    static detail = companyID => `/investors/companies/${companyID}/`;
    static detailBySlug = slug => `/companies/${slug}/`;
    static participating = '/public/startups_batch/';
    static participatingStartups = '/companies/participating_companies/';
    static inactiveStartups = '/companies/inactive_companies/';
  };

  static Bonus = class {
    static bonusAllocation = '/fundraisings/doGetBonusAllocationInfo/';
  };

  static User = class {
    static register = '/investors/auth/register/';
    static facebook = '/rest-auth/investors/facebook/';
    static google = '/rest-auth/investors/google/';
    static apple = '/rest-auth/investors/apple/';
    static linkedin = '/rest-auth/linkedin/';
    static updateProfile = '/users/doUpdateProfile/';
    static profile = '/investors/profile/';
    static bonusGroup = '/bonus_group/';
    static profileVisibility = '/investors/change-visibility/';
    static updateProfilePhoto = '/users/doUpdateProfilePhoto/';
    static doUpdateProfile = '/users/doUpdateProfile/';
    static stepTwo = '/investors/auth/register/step_two/';
    static stepThree = '/investors/auth/register/step_three/';
    static doUpdatePersonalInfo = '/investors/doUpdatePersonalInfo/';
    static doUpdateContactInfo = '/investors/doUpdateContactInfo/';
    static missingInfo = '/investors/missing_info/';
    static veriffStatus = '/veriff_status/';
    static veriffStatuses = '/veriff_statuses/';
    static registerVeriffStatuses = id =>
      `/veriff_statuses/${id}/doRegisterGTMEvent/`;
    static acceptMarketingPromo = promoID =>
      `/investors/marketing_promotions/${promoID}/doAcceptPromotion/`;
    static declineMarketingPromo = promoID =>
      `/investors/marketing_promotions/${promoID}/doDeclinePromotion/`;
    static myLocation = '/my_location/';
    static unsubscribeEmails = '/users/doUnsubscribe/';
    static subscribeEmails = '/users/doSubscribe/';
    static utmDetail = '/utm_details/';
    static veriffSkip = '/veriff_skip/';
    static investorType = '/investors/types/';
    static sentCodes = '/send_code/';
    static validatePhoneNumber = '/validate_code/';
    static sentWhatsappCode = '/resend_whatsapp_temporary_code/';
    static emailVerification = '/investors/verify-email/';
    static sendEmailVerificationCode =
      '/investors/send_email_verification_code/';
  };

  static Founders = class {
    static participatingFounders = '/investors/participating_founders/';
    static inactiveFounders = '/investors/inactive_founders/';
    static detail = id => `/founders/${id}/doGetFounderPageDetails/`;
    static fundraisingStatus = founderID =>
      `/investors/founder_status/${founderID}/`;
  };

  static Investors = class {
    static participatingInvestors = '/investors/participating_investors/';
    static inactiveInvestors = '/investors/inactive_investors/';
  };

  static Common = class {
    static founderInfo = id => `/founders/${id}/`;
    static file = 'files/';
    static photo = 'images/';
  };

  static Blog = class {
    static articles = '/articles/';
    static article = slug => `/articles/${slug}/`;
  };

  static Dashboard = class {
    static balance = '/investors/dashboard/balance/';
    static fundraisingDates = 'fundraising_dates/';
    static earnings = '/investors/dashboard/earnings/';
    static happyFounders = '/investors/dashboard/happy_founders/';
    static loanGroups = '/investors/dashboard/loan_groups/';
  };

  static Funds = class {
    static allocateInfo = '/investors/allocate_info/';
    static preAllocateInfo = '/investors/investment_intermediate_info/';
    static reinvestStatus = '/investors/reinvest/';
    static autoInvestStatus = '/investors/auto_invest/';
    static allocateFunds = '/investors/allocate/';
    static withdrawInfo = '/withdraw_info/';
    static withdrawReq = '/withdraw/';
    static withdrawHistory = '/withdraw_requests/';
    static pendingTransactions = '/payment_sessions/';
    static withdrawRequest = reqID => `/withdraw_requests/${reqID}/`;
    static bankDetails = '/investors/bank_details/';
    static bankDetailByID = id => `/investors/bank_details/${id}/`;
    static countryInSepa = countryCode =>
      `/sepa_check/?country_code=${countryCode}`;
    static ibanCheck = '/iban_check/';
  };

  static Loans = class {
    static list = '/loans/doGetInvestorRoundLoans/';
    static loanTerms = '/loan_groups/investor_terms/';
    static repaymentSchedule = '/repayment_schedule/';
    static repaymentScheduleByLoanID = loanID =>
      `/repayment_schedule/${loanID}/`;
    static paymentHistory = id => `/loan_payment_histories/${id}/`;
    static loansByFundraisingID = id => `/fundraisings/${id}/loans/`;
    static loanEarningsByFundraisingID = (id, groupId) =>
      `/fundraisings/${id}/earnings_schedule/${groupId}/`;
    static fundsInRecoveryByFundraisingID = id =>
      `/fundraisings/${id}/recovery_funds/`;
    static startupsByFundraisingID = id => `/fundraisings/${id}/startups/`;
    static repaymentScheduleByFundraisingID = id =>
      `/fundraisings/${id}/repayment_schedule/`;
    static agreementsByFundraisingID = id => `/fundraisings/${id}/agreements/`;
    static paymentHistoryByFundraisingID = id =>
      `/fundraisings/${id}/loan_payment_histories/`;
    static sellingLoans = companyId =>
      `/investors/companies/${companyId}/loans/`;
    static loanSecurityByFundraisingId = fundraisingId =>
      `/fundraisings/${fundraisingId}/guarantee_letters/`;
  };

  static Activity = class {
    static list = '/activities/';
    static types = '/activity_types/';
    static taxReport = '/activities/investors/tax_report/';
  };

  static RoundHistory = class {
    static pastRoundStats = `/public/investors/rounds_stats/`;
    static allRounds = '/public/investors/rounds_by_year/';
    static roundRepaymentProgress = fundraisingID =>
      `/public/investors/round_progress/${fundraisingID}/`;
    static roundLoanGroupStatements = fundraisingID =>
      `public/round_loan_groups/${fundraisingID}/`;
    static roundCountries = `/public/fundraising_countries_stats/`;
    static batchByID = fundraisingID =>
      `public/investors/round_batch/${fundraisingID}/`;
  };

  static Stripe = class {
    static session = '/create_checkout_session/';
    static calculate = '/calculate_total_amount/';
    static info = sessionID => `/payment_sessions/${sessionID}/`;
  };

  static LHV = class {
    static session = '/create_lhv_payment_session/';
    static methods = '/payment_methods_with_fees/';
    static paymentSession = '/lhv_payment_session/';
  };

  static Questionnaire = class {
    static list = '/investors/assessment/questions/';
    static status = '/investors/assessment/';
    static submit = '/investors/assessment/submit/';
    static update = '/investors/assessment/update_status/';
  };

  static Investing = class {
    static participatingInvestors = '/investors/participating_investors/';
    static participatingFounders = '/investors/participating_founders/';
    static futureFounders = '/investors/future_founders/';
    static prioritySelections = '/investors/selection_founders/';
    static priorityFounders = '/investors/priority_founders/';
    static standardFounders = '/investors/standard_founders/';
    static nonPriorityFounders = '/investors/no_priority_founders/';
    static latestHappyFounders = '/investors/latest_happy_founders/';
    static foundersByLoanGroup = groupID => `/loan_groups/${groupID}/founders/`;
    static detailedFounders = groupID =>
      `/loan_groups/${groupID}/detailed_founders/`;
    static status = '/investors/investment_status/';
    static strategy = '/investors/investment_strategy/';
    static loanGroups = '/investors/dashboard/loan_groups/';
    static photosMaxDiversification = '/investors/diversification_founders/';
    static photosParticular = '/investors/group_founders/';
    static investmentGroups = '/investors/investment_groups/';
    static selectedGroups = '/investors/selected_groups/';
    static selectedFounders = '/investors/selected_founders/';
    static happyFounders = '/investors/latest_happy_founders/';
    static results = '/investors/investment_results/';
    static fundraisingStats = '/fundraising_stats/';
    static fundraisingCountriesStats = '/public/fundraising_countries_stats/';
    static doResetInvestment = '/investors/doResetInvestment/';
    static investmentConfirmation = '/investors/investment_confirmation/';
    static investmentCalculator = '/public/investment_calculator/';
    static termsOfUseStatus = '/investors/advanced-terms-of-use/';
    static repaymentSchedule = id => `/investors/fundraisings/${id}/schedule/`;
    static increasedIncome = '/fundraisings/increased_income/';
  };

  static Help = class {
    static all = '/helps/?source=investor';
    static section = source => `/mini_faqs/?page=${source}`;
    static forumMessages = '/forum_messages/';
  };

  static Referrals = class {
    static getCode = '/referrals/doGetReferralCode/';
    static list = '/referrals/';
    static emailSend = '/referrals/doInviteReferral/';
    static acceptPartnerTermsOfUse = '/terms_of_use/doAcceptPartnerTermsOfUse/';
    static checkRefCodeValidity = code => `/check_referral/${code}/`;
    static checkRefCode = '/referrals/status/';
    static applyRefCode = '/referrals/activate/';
  };

  static Complaint = class {
    static complaints = '/investors/complaints/';
    static complaint = id => `/investors/complaints/${id}/`;
    static participating_fundraising = '/investors/participating_fundraising/';
  };
}
