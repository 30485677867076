import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { getLoanEarningsByFundraisingID } from '../../../store/actions/loanActions';
import { prettyDate, prettyFloatMoney } from '../../../helpers/utils';
import { LOAN_GROUPS } from '../../../helpers/constants';
import Tooltip from '../../../components/Tooltip';
import { InfoIcon } from '../../../components/UI/Icons';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import { LOAN_EARNINGS_STATUSES } from '../../../helpers/loan';
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../helpers/common';
import s from './index.module.scss';

export const LoanEarningsSchedule = ({ fundraisingID }) => {
  const dispatch = useDispatch();
  const [groupId, setGroupId] = useState(null);
  const { data: loans } = useSelector(
    state => state.loanStore.loansByFundraisingID
  );
  const { data, loading } = useSelector(
    state => state.loanStore.loanEarningsByFundraisingID
  );
  const { balance } = useSelector(state => state.dashboardStore);

  useEffect(() => {
    if (groupId) {
      dispatch(getLoanEarningsByFundraisingID(fundraisingID, groupId));
    }
  }, [fundraisingID, groupId]);

  useEffect(() => {
    if (loans?.length && !groupId) {
      setGroupId(loans[0].id);
    }
  }, [loans, groupId]);

  if (!loans?.length || !data) {
    return null;
  }

  const { total_earnings, list } = data;

  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">Earnings schedule</h2>
      <div className="f-16-24" style={{ marginTop: '20px' }}>
        Here you can track your monthly accrued income and see the estimated
        amount you&apos;ll receive at the end of the 6-month loan period. You
        can see how your earnings build over time, with full details available
        in the repayment schedule below.
      </div>
      <div className={s.tabs}>
        {loans?.map(item => (
          <div
            key={item.id}
            onClick={() => setGroupId(item.id)}
            className={classnames(
              s.tabs_item,
              item.id === groupId && 'active',
              'f-20 f-400'
            )}
          >
            {item.title}
          </div>
        ))}
      </div>

      {!loading &&
        (data?.list.length ? (
          <>
            <div className={s.table_wrapper}>
              <div className={s.table_inner}>
                <div className={s.table}>
                  <div className={classnames(s.thead)}>
                    <div className={s.thead_col}>Due date</div>
                    <div className={s.thead_col}>Loan Balance</div>
                    <div className={s.thead_col}>Earnings (%)</div>
                    <div className={s.thead_col}>Earnings (€)</div>
                    <div className={s.thead_col}>Status</div>
                  </div>

                  <div className={s.tbody}>
                    {list.map((loan, idx) => (
                      <div
                        className={classnames(s.tbody_row, loan.status)}
                        key={idx}
                      >
                        <div className={s.tbody_col}>
                          {prettyDate(loan.deadline, DATE_FORMAT_DD_MMM_YYYY)}
                        </div>
                        <div className={s.tbody_col}>
                          {prettyFloatMoney(loan.loan_balance)}
                        </div>
                        <div className={s.tbody_col}>
                          {(LOAN_EARNINGS_STATUSES.accrued === loan.status ||
                            groupId === LOAN_GROUPS.moderate.id) && (
                            <span>{loan.earnings_percent}%</span>
                          )}

                          {groupId === LOAN_GROUPS.conservative.id &&
                            LOAN_EARNINGS_STATUSES.scheduled ===
                              loan.status && (
                              <b>
                                up to {loan.earnings_percent}%{' '}
                                {balance && (
                                  <Tooltip
                                    clickMode
                                    icon={InfoIcon}
                                    position="top center"
                                    renderContent={() => (
                                      <div
                                        className={classnames(
                                          s.tooltip,
                                          'f-16  f-400'
                                        )}
                                      >
                                        {balance.invested >= 10000 ? (
                                          <span>
                                            By maintaining an active investment
                                            balance of €10,000, you&apos;ll
                                            automatically receive an increased
                                            income of 1% per month. If your
                                            balance falls below €10,000,
                                            you&apos;ll need to top up your
                                            account and invest at least €100 per
                                            month to keep the 1% increased
                                            income. Otherwise, the return for
                                            that month will revert to the base
                                            income rate of 0.75%.
                                          </span>
                                        ) : (
                                          <span>
                                            If you top up your account and
                                            invest a minimum of €100 per month,
                                            you qualify for an increased income
                                            of 1% per month on your outstanding
                                            loan amount. If the threshold
                                            isn&apos;t met, the return for that
                                            month is recalculated at the base
                                            income rate of 0.75%. Returns are
                                            accrued monthly but paid out at the
                                            end of the loan term. Learn more{' '}
                                            <MyLink
                                              href={APP_LINKS.helpLoanTerms()}
                                              underline
                                            >
                                              here
                                            </MyLink>
                                            .
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  />
                                )}
                              </b>
                            )}
                        </div>
                        <div className={s.tbody_col}>
                          {prettyFloatMoney(loan.earnings_currency)}
                        </div>
                        <div
                          className={s.tbody_col}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {loan.status}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className={s.total}>
              <div className={s.total_label}>Total earnings:</div>
              <div className={s.total_value}>
                {prettyFloatMoney(total_earnings)}
              </div>
            </div>
          </>
        ) : (
          <div className={s.empty_text}>No data</div>
        ))}
    </div>
  );
};
